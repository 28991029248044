import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { PageType } from 'src/app/feature/post-auth/modules/terms-pages/enums/page-type.enums';
import { TermsPagesService } from 'src/app/feature/post-auth/modules/terms-pages/services/terms-pages.service';

@Component({
  selector: 'app-cms',
  templateUrl: './cms.component.html',
  styleUrls: ['./cms.component.scss']
})
export class CmsComponent implements OnInit {

  screenType = PageType.PRIVACY_POLICY;
  data: string = ''
  private subscription: Subscription = new Subscription();

  constructor(private activatedRoute: ActivatedRoute,
    private termsService: TermsPagesService,
  ) { }

  ngOnInit(): void {
    this.getParams()
  }

  private getParams(): void {
    this.activatedRoute.params.subscribe((params: any) => {
      if (params.type) {
        this.screenType = params['type'];
        if(this.screenType !== 'deleteAccount'){
          this.getData(this.screenType)
        }
      }
    })
  }

  private getData(type: string): void {
    this.subscription.add(
      this.termsService.httpGetPageDetail(type).subscribe({
        next: (res: any) => {
          if (res && res.data) {
            this.data = res.data.description
          }
        },
      }),
    );
  }

}
