import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { StripeServiceService } from '../cms-service/stripe-service.service';

@Component({
  selector: 'app-refresh-page-stripe',
  templateUrl: './refresh-page-stripe.component.html',
  styleUrls: ['./refresh-page-stripe.component.scss']
})
export class RefreshPageStripeComponent {
  private subscription: Subscription = new Subscription();
  public pageStatus : string = ''
  public driverId : string = ''
  constructor(private router: Router,private route: ActivatedRoute,public stripeService: StripeServiceService
  ) { }

  ngOnInit(): void {
    // this.pageStatus = this.router.url.includes('activated') ? 'success' : 'error';
    this.driverId = this.route.snapshot.params['id'];
    if(this.driverId){
      this.AccountVerifyLink(this.driverId)
    }
  }
  
  AccountVerifyLink(driverId:string){
    this.subscription.add(
      this.stripeService.httpStripe(driverId).subscribe((res: any) => {
        if (res && res.data.accountLink) {
          window.location.href = res.data.accountLink.url;
        }
      })
    );
  }
}
