import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-payment-status-page',
  templateUrl: './payment-status-page.component.html',
  styleUrls: ['./payment-status-page.component.scss']
})
export class PaymentStatusPageComponent {

  public pageStatus : string = ''
  
  constructor(private router: Router
  ) { }

  ngOnInit(): void {
    this.pageStatus = this.router.url.includes('success') ? 'success' : 'error';
  }

}
