import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CmsComponent } from './shared/components/cms/cms.component';
import { VerifyEmailComponent } from './shared/components/verify-email/verify-email.component';
import { NoPermissionsFoundComponent } from './shared/components/no-permissions-found/no-permissions-found.component';
import { PaymentStatusPageComponent } from './shared/components/cms/payment-status-page/payment-status-page.component';
import { StripePaymentComponent } from './shared/components/cms/stripe-payment/stripe-payment.component';
import { RefreshPageStripeComponent } from './shared/components/cms/refresh-page-stripe/refresh-page-stripe.component';

const routes: Routes = [
  // {
  //   path: '',
  //   loadChildren: () =>
  //     import('./feature/feature.module').then((m) => m.FeatureModule),
  // },
  { path: 'cms/:type', component: CmsComponent, title: 'CMS' },
  { path: 'payment-status/success', component: PaymentStatusPageComponent, title: 'PAYMENT' },
  { path: 'payment-status/error', component: PaymentStatusPageComponent, title: 'PAYMENT' },
  { path: 'stripe-account-status/activated', component: StripePaymentComponent, title: 'PAYMENT' },
  { path: 'refresh-stripe/:id', component: RefreshPageStripeComponent, title: 'PAYMENT' },
  // { path: 'no-permissions-found', component: NoPermissionsFoundComponent, title: 'Permissions Not Found' },
  // { path: 'verify-email', component: VerifyEmailComponent, title: 'Email Verification' },
  // {
  //   path: '**',
  //   redirectTo: '',
  //   pathMatch: 'full',
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
