import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-stripe-payment',
  templateUrl: './stripe-payment.component.html',
  styleUrls: ['./stripe-payment.component.scss']
})
export class StripePaymentComponent {
  public pageStatus : string = ''
  
  constructor(private router: Router
  ) { }

  ngOnInit(): void {
    this.pageStatus = this.router.url.includes('activated') ? 'success' : 'error';
  }
}
