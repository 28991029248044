<section class="section-b-space sucess-page">
    <!-- <div   class="modal-content">
        <div class="modal-body">
            <div class="verify text-center">
                <img src="assets/check.svg" height=80px class="m-3" alt="">
                <h4>Thankyou </h4>
                <p class="mb-4">Your payment is Completed !</p>
            </div>
        </div>
    </div> -->
    <div class="container-fluid screen-height mt-5" *ngIf="pageStatus=='success'">
        <div  class="modal-content">
            <div class="modal-body">
                <div class="verify text-center">
                    <img src="assets/check.svg" height=80px class="m-3" alt="">
                    <h5>Your account has been successfully verified with us and Stripe for payouts.</h5>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="container-fluid screen-height mt-5" *ngIf="pageStatus=='error'">
        <div  class="modal-content">
            <div class="modal-body">
                <div class="verify text-center">
                    <img src="assets/delete-icon-fill.svg" height=80px class="m-3" alt="">
                    <h4>Your payment didn't go through. Please try again or contact support.</h4>
                </div>
            </div>
        </div>
    </div> -->

    
    <!-- <div *ngIf="this.isSuccessPage=='error'" class="modal-content">
        <div class="modal-body">
            <div class="verify text-center">
                <img src="assets/img/delete-icon-fill.svg" height=80px class="m-3" alt="">
                <h4>Session Time Out!</h4>
            </div>
        </div>
    </div> -->
</section>
