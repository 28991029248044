import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_Routes } from 'src/app/shared/constants';
@Injectable({
  providedIn: 'root'
})
export class StripeServiceService {

  constructor(private http: HttpClient) { 

  }

  public httpStripe(id:string): Observable<HttpResponse<any>> {
    const url = API_Routes.STRIPE.ACCOUNT_VERIFY + '/' +id
    return this.http.get<HttpResponse<any>>(url);
  }
}
